<template>
  <div id="app" >
<div class="background_load" v-if="loading">
  <div class="d-flex justify-content-center align-items-center" style="margin-top:25%">
    <img src="/Icon_v2-APNG.gif" style="width:100px">
  </div>
</div>
    <router-view />

  </div>
</template>
<script>

  
export default {
  name: 'App',
  components: {
    
  },
   data: () => ({
    loading: true,
    
  }),
  created() {
    this.$root.$refs.app = this;
  },
  methods: {
    showToast(variant, msg) {
      this.$bvToast.toast(msg, {
        title: process.env.VUE_APP_NAME,
        autoHideDelay: 5000,
        solid: true,
        variant: variant,
        noCloseButton : true,
      });
    },
    showLoader(i) {
      if (i == 1) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
  },
   watch: {
    $route() {
 this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);

    }
  },
  mounted() {
    this.loading = true;
    document.body.style.overflow = "auto"; // Enable scrolling on load
  }
}
</script>
<style>
  .background_load{
  width: 100vw;
  height: 100vw;
  margin:0;
  background:#ffff;
  position: fixed;
  z-index: 99999;
  top:0;
}
.background_load::-webkit-scrollbar {
  display: none;
}

</style>